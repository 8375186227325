import React from "react";

interface SectionProps {
    className?: string;
    children: React.ReactNode;
}

const Section:React.FC<SectionProps> = ({ className, children }) => {
  return (
    <section className={className}>
      <div className="container mx-auto p-8">{children}</div>
    </section>
  );
};
export default Section;