import React from "react";

interface FAQItemProps {
  index: number;
  isOpen: boolean;
  toggleItem: (index: number) => void;
  children: React.ReactNode;
}

const FAQItem: React.FC<FAQItemProps> = ({ index, isOpen, toggleItem, children }) => {
  const spanChildren = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && (child.type as any) === "span"
  );

  const ulChildren = React.Children.toArray(children).filter(
    (child) => React.isValidElement(child) && (child.type as any) === "ul"
  );

  return (
    <div className="p-2 bg-white cursor-pointer ">
      <li onClick={() => toggleItem(index)} className="">
        <span className="font-bold pl-3">{spanChildren}</span>
        {isOpen && (
          <ul className="list-disc pl-8 bg-white" >
            {ulChildren.map((child, idx) => (
              <li key={idx} className="font-semibold">{child}</li>
            ))}
          </ul>
        )}
      </li>
    </div>
  );
};

export default FAQItem;
