
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import { ConsultationOptionProps } from './ConsultationOption';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface ConsultationSliderProps {
    plans: ConsultationOptionProps[]
}
const ConsultationSlider: React.FC<ConsultationSliderProps> = ({ plans }) => {
    let navigate = useNavigate();
    const handleOptionClick = (chosenOption: string) => {
        navigate(`/confirmation?option=${chosenOption}`);
    };
    const slides: React.CSSProperties = {

    };

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "slides",
        arrows: true
    };

    function openGoogleForm() {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSe64Qm4Dnq5b3-r8V2Vg7saWUNfnpKqB_KE8Q6pVXEA_JEycg/viewform', '_blank');
    }

    return (
        <div className="bg-secondary-accent2 rounded-xl p-8 flex flex-col h-full" style={{ boxSizing: 'border-box' }}>
            <div className='bg-white rounded-lg flex flex-col h-full'>
                <Slider {...sliderSettings}>
                    {plans.map((plan, index) => (
                        <div key={index} className='cursor-grab py-4 px-8 flex flex-col h-full justify-between'>
                            <div className='w-full flex-grow h-full flex flex-col'>
                                <div className="text-center">
                                    <h3 className="text-xl font-bold">{plan.title}</h3>
                                    <p>{plan.duration}</p>
                                    <p className='font-bold text-lg'>{plan.price}</p>
                                </div>

                                <p className="mt-4 font-medium">{plan.description}</p>
                                <ul className="list-disc mt-2 ">
                                    {plan.features.map((feature, i) => (
                                        <li key={i}>{feature}</li>
                                    ))}
                                </ul>
                            </div>
                            {/* <button onClick={() => handleOptionClick(plan.id)} className="flex mx-auto text-white bg-primary-accent hover:bg-primary-accent2 font-semibold px-12 py-2 rounded-xl mt-4 transition">Pilih</button> */}
                            <button onClick={() => openGoogleForm()} className="flex mx-auto text-white bg-primary-accent hover:bg-primary-accent2 font-medium px-12 py-2 rounded-xl mt-4 transition ">Pilih</button>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>


    );
};

export default ConsultationSlider;
