import React, { useState } from "react";
import FAQItem from "./FAQItem";

const FAQSection = () => {
    const [openItems, setOpenItems] = useState<number[]>([]);

    const toggleItem = (index: number) => {
        const updatedItems = [...openItems];
        if (updatedItems.includes(index)) {
            updatedItems.splice(updatedItems.indexOf(index), 1);
        } else {
            updatedItems.push(index);
        }
        setOpenItems(updatedItems);
    };

    return (
        <ol className="list-decimal px-6 py-2 bg-white rounded-xl  select-none">
            <FAQItem index={0} isOpen={openItems.includes(0)} toggleItem={toggleItem}>
                <span className="font-bold">Konsultasinya online atau offline?</span>
                <ul className="font-medium">Untuk saat ini konsultasi kita lakukan secara online, dan jam konsultasi bisa menyesuaikan jadwal kamu.</ul>
            </FAQItem>
            <FAQItem index={1} isOpen={openItems.includes(1)} toggleItem={toggleItem}>
                <span className="font-bold">Platform apa yang digunakan buat konsultasi?</span>
                <ul className="font-medium">Untuk konsultasi chat dan telepon akan melalui WhatsApp.</ul>
                <ul className="font-medium">Untuk video akan melalui Zoom.</ul>
            </FAQItem>
            <FAQItem index={2} isOpen={openItems.includes(2)} toggleItem={toggleItem}>
                <span className="font-bold">Berapa lama durasi konsultasi?</span>
                <ul className="font-medium">Durasi konsultasi nya 1 jam/sesi (berlaku untuk konsultasi via chat, telepon, dan video).</ul>
            </FAQItem>
            <FAQItem index={3} isOpen={openItems.includes(3)} toggleItem={toggleItem}>
                <span className="font-bold">Bagaimana dengan kerahasiaan data dan hasil konsultasi?</span>
                <ul className="font-medium">Tenang ajah, gak perlu takut. Data konsultasi hanya dapat diakses oleh konselor dan klien. Jadi, apapun yang kamu ceritakan akan aman.</ul>
            </FAQItem>
            <FAQItem index={4} isOpen={openItems.includes(4)} toggleItem={toggleItem}>
                <span className="font-bold">Bagaimana dengan jadwal konsultasinya?</span>
                <ul className="font-medium">Jadwal konsultasi tersedia dari jam 9am - 9pm WIB (kamu harus booking saat isi data dan pendaftaran).</ul>
            </FAQItem>
        </ol>

    );
};

export default FAQSection;
