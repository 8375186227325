/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import TestimonialSlider from "./CustomSlider";
const TestimonialSection = () => {
  const testimonials = [
    { date: '1/14/2024', plan: 'Chat konseling 3 hari', rating: 4, message: 'Thank you udah didengerin cerita dan sarannya kak, aku happy ada yang dengerin ceritaku', initials: 'MT' },
    { date: '1/19/2024', plan: 'Chat konseling 3 hari', rating: 5, message: 'Mentor tidak hanya sebagai teman tapi juga sebagai kakak, dan tenaga profesional yang memberikan support positif', initials: 'FS' },
    { date: '1/20/2024', plan: 'Chat konseling 3 hari', rating: 5, message: 'Aku seneng bgt jadi bisa lebih ngerti apa yg sebenarnya lagi aku rasain berkat pengetahuan psikologi kakaknya.', initials: 'SN' },
    { date: '1/25/2024', plan: 'Chat konseling 3 hari', rating: 5, message: 'Terima kasih buat konselingnya, disaat saya butuh teman cerita ada teman bicaraku. Bisa konseling dengan harga terjangkau. Konselornya sabar dan bisa membuka pandangan baru.', initials: 'NS' },
    { date: '1/26/2024', plan: 'Chat konseling 7 hari', rating: 5, message: 'Kak Van baik banget dia bimbing aku utk menemukan solusi atas masalahku. Aku bener2 ngerasa dimanusiakan dengan di dengar dan diberi pemantik2 utk berpikir mandiri.', initials: 'D' },
    { date: '1/27/2024', plan: 'Video konseling', rating: 5, message: 'lebih lega sih karna didengar dan ga dijudge sama sekali, jujur untuk ikut konseling aku rada takut banget karna ga pernah juga kan tapi better feels nya udah lumayan membaik. thankyou so much yaa', initials: 'AMH' },
    { date: '1/27/2024', plan: 'Chat konseling 3 hari', rating: 5, message: "I don't make emotional decisions. I feel better than before :)))", initials: 'IA' },
    { date: '1/30/2024', plan: 'Chat konseling 7 hari', rating: 5, message: 'Menurutku setelah menjalani konsultasi kw kk, aku jd tau aku kenapa emosinya ga stabil. Bahkan setelah konsul aku jd lbh tenang dan tau harus apa dan gmna tengkyu ya ka', initials: 'A' },
    { date: '2/2/2024', plan: 'Chat konseling 3 hari', rating: 5, message: 'Insightful, semua ceritaku didengar, dan Kakaknya sangat ramah. Makasi:)', initials: 'A' },
    { date: '2/12/2024', plan: 'Telepon konseling', rating: 4, message: 'Lumayan lega ada konselor yang mau mendengarkan cerita cerita ku yang agak gak berat sebenarnya, cuman bisa bersyukur aja masih ada orang yang masih bersedia mendengarkan dan menyimpan ceritaku.', initials: 'MB' },
    { date: '2/13/2024', plan: 'Chat konseling 3 hari', rating: 4, message: 'Fast respon, rajin reminder jg soal jdwl konsultasi. Enaknya kita dikasih space ato jeda, jd kita bs merenung lagi ttg masalah yg kita hadapi. Kita juga jd bisa berpikir dgn kepala dingin', initials: 'FP' },
    { date: '2/14/2024', plan: 'Chat konseling 3 hari', rating: 5, message: 'seneng banget aku yg awalnya hopeless jadi punya harapan lagi', initials: 'F' },
    { date: '2/15/2024', plan: 'Chat konseling 3 hari', rating: 5, message: 'Vania bener2 ngasih solusi dan perspektif lain sebagai orang yang ahli di bidangnya. Nyaman banget cerita sama dia', initials: 'A' },
    { date: '2/22/2024', plan: 'Chat konseling 3 hari', rating: 4, message: 'Kakaknya responsif, saran2 nya membangun2', initials: 'AD' },
    { date: '2/22/2024', plan: 'Chat konseling 3 hari', rating: 5, message: 'Kakak nya kasih pov solusi yang bener benar dibutunkan dan sesuai dengan topik permasalah aku. Singkat, padat, dan jelas sekali', initials: 'LC' },
    { date: '2/24/2024', plan: 'Chat konseling 3 hari', rating: 4, message: 'Terimakasih sudah mau menyimak cerita saya & saran-saran yang positif, karena kalau kita lagi di posisi emotional baggage atau mental drop memang harus cerita ke orang yang netral', initials: 'Na' },
    { date: '2/26/2024', plan: 'Telepon konseling', rating: 5, message: 'Thank you aku jadi mengerti apa yang terjadi di diriku dan tau apa yang harus dilakukan. Penjabaran yang diberikan sangat mudah dipahami.', initials: 'Na' },
    { date: '5/17/2024', plan: 'Telepon konseling', rating: 5, message: 'Bs utk membuka fikiran utk jlnin kedepan nya', initials: 'Mf' },
    { date: '5/18/2024', plan: 'Chat konseling 1 hari', rating: 4, message: 'Akhirnya saya bisa dan berani bicara tentang apa yg selama ini saya pendam.', initials: 'HY' },
    { date: '5/22/2024', plan: 'Video konseling', rating: 5, message: 'Sangat mendapat masukan yg berarti', initials: 'Rs' },
    { date: '5/27/2024', plan: 'Chat konseling 3 hari', rating: 5, message: 'Terimakasih teman bicaraku, setiap sesinya aku selalu mendapatkan insight yg bagus untuk aku terapkan dan direnungi.', initials: 'M' },
    { date: '5/29/2024', plan: 'Chat konseling 1 hari', rating: 5, message: 'Cukup terbantu setelah cerita', initials: 'SR' },
    { date: '5/31/2024', plan: 'Chat konseling 1 hari', rating: 4, message: 'baik, cukup bisa memancing perasaan perasaan yg dipendam', initials: 'Adi' }
  ];

  return (
    <div>
      <TestimonialSlider testimonials={testimonials} />
    </div>
  );
};

export default TestimonialSection;
