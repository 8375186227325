/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect, ChangeEvent, MouseEventHandler, FormEvent } from 'react';
import { useNavigate } from "react-router-dom";
import Section from "../home/components/Section";
import { useLocation } from 'react-router-dom';
// import axios, { AxiosResponse } from "axios";
// const spreadsheetId = "1EHzCxFRp_AP0M_bxhWb0ATMfN-aqllO3ew-FxN3gapU";
// const sheetName = "bookings";

// const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}:append`;

// const dataToAppend = {
//   values: [["New Data", "123", "Another Value"]],
// };

interface ConfirmationOptionProps {
    option: string;
}

const Form: React.FC = () => {
    const location = useLocation()
    const chosenOption = new URLSearchParams(location.search).get('option');
    const [values, setValues] = React.useState([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [pricingInfo, setPricingInfo] = useState<{ price: number; desc: string }>({
        price: 0,
        desc: '',
    });
    const [formData, setFormData] = useState({
        name: '',
        city: '',
        dob: '',
        contact: '',
        description: '',
        price: 0,
        image: null,
    });

    useEffect(() => {
        // Fetch pricing info based on the chosen option
        fetchPricingInfo(chosenOption);
    }, [chosenOption]);


    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        // If the input is a file input (image), handle it separately
        if (e.target.type === "file") {
            const file = (e.target as HTMLInputElement).files?.[0];

            if (file) {
                const reader = new FileReader();

                reader.onload = (event) => {
                    const base64Data = event.target?.result as string;
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        [name]: base64Data,
                    }));
                };

                reader.readAsDataURL(file);
            }
        } else {
            // For text inputs, handle as usual
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    const handleBooking = async (e: any) => {
        e.preventDefault();
        formData['description'] = pricingInfo.desc
        formData['price'] = pricingInfo.price
        console.log(formData)
        try {
            const response = await fetch('/api/book', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Error submitting form');
            }
            const responseData = await response.json();
            console.log(responseData)

            console.log('Form submitted successfully');
        } catch (error: any) {
            console.error('Error submitting form:', error.message);

        }
    };

    const fetchPricingInfo = (option: string | null) => {
        const chatCounselingPlan = [
            {
                id: 'sahfa',
                title: 'Chat Konseling',
                duration: '1 Hari (1 sesi)',
                price: 50000,
                description: 'Untuk pemula dan mau coba konsultasi:',
                features: [
                    'Pendampingan berkala selama 1 hari. 1 sesi durasinya 1 jam',
                    'Dilakukan via Whatsapp',
                    'Cocok untuk yang baru pertama kali on-going counseling',
                    'Konselor akan menjawab kamu secara interaktif selama durasi sesi',
                    'Kamu bisa mendapatkan tips praktis dalam waktu singkat',
                ],
            },
            {
                id: 'jkadg',
                title: 'Chat Konseling',
                duration: '3 Hari (3 sesi)',
                price: 90000,
                description: 'Untuk kamu yang mencari solusi lebih lanjut:',
                features: [
                    'Pendampingan berkala selama 3 hari. 1 sesi durasinya 1 jam',
                    'Dilakukan via Whatsapp',
                    'Cocok untuk yang ingin melanjutkan konkseling 1 hari',
                    'Konselor akan menjawab kamu secara interaktif selama durasi sesi',
                    'Kamu bisa mendapatkan tips praktis dalam waktu singkat',
                ],
            },
            {
                id: 'olpkf',
                title: 'Chat Konseling',
                duration: '7 Hari (7 sesi)',
                price: 175000,
                description: 'Untuk kamu yang mau intensif:',
                features: [
                    'Pendampingan berkala selama 7 hari. 1 sesi durasinya 1 jam',
                    'Dilakukan via Whatsapp',
                    'Cocok untuk maintenance dan yang mau bertumbuh',
                    'Konselor akan menjawab kamu secara interaktif selama durasi sesi',
                    'Serasa punya teman dekat yang bisa mendengarkan masalah kamu dan memberikan tips serta masukan',
                ],
            }
        ];

        const telponCounselingPlan = {
            id: 'qwdcx',
            title: 'Telpon Konseling',
            duration: '1 Sesi',
            price: 75000,
            description: 'Program konseling melalui telepon untuk sesi yang lebih fleksibel:',
            features: [
                'Durasi selama 1 jam per sesinya',
                'Dilakukan via Whatsapp',
                'Dapat berdiskusi langsung tentang masalah yang dihadapi dan dapat masukan secara instant',
                'Dapat berbincang dengan konselor kami secara realtime',
            ],
        };

        const videoCounselingPlan = {
            id: 'vbchwe',
            title: 'Video Konseling',
            duration: '1 Sesi',
            price: 100000,
            description: 'Program konseling melalui video call untuk sesi yang lebih interaktif:',
            features: [
                'Durasi selama 1 jam per sesinya',
                'Dilakukan di Zoom / Google Meet',
                'Dapat berdiskusi langsung tentang masalah yang dihadapi dan dapat masukan secara instant',
                'Dapat berbincang dengan konselor kami secara realtime',
            ],
        };
        const selectedPlan = [...chatCounselingPlan, telponCounselingPlan, videoCounselingPlan].find(
            (plan) => plan.id === option
        );

        if (selectedPlan) {
            setPricingInfo({ price: selectedPlan.price, desc: `${selectedPlan.title} x ${selectedPlan.duration}` });
        } else {
            setPricingInfo({ price: 0, desc: '' }); // Default or handle unknown options
        }

    }

    let navigate = useNavigate();
    const routeChange = () => {
        let path = '/';
        navigate(path);
    }

    // const handleFileChange = (e: any) => {
    //     const file = e.target.files[0];
    //     setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         image: file,
    //     }));
    // };



    return (
        <Section className="h-screen bg-gray-200 text-primary-accent flex items-center">
            <div className="max-w-lg mx-auto my-auto p-6 bg-gray-50 rounded-md shadow-md">
                <span onClick={routeChange} className="flex mb-4 cursor-pointer underline font-sm rounded-md text-primary-lighter1 hover:text-primary-lighter2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Kembali
                </span>
                <h2 className="text-2xl font-semibold mb-4">Konfirmasi Pembayaran</h2>
                <form onSubmit={handleBooking}>
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-sm font-medium mb-2">Nama <span className="text-red-500">*</span></label>
                        <input
                            onChange={handleChange}
                            required
                            type="text"
                            id="name"
                            name="name"
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="Nama (Jika ingin Anonymous, tulis inisial saja)"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="city" className="block text-sm font-medium mb-2">Asal Kota <span className="text-red-500">*</span></label>
                        <input
                            onChange={handleChange}
                            required
                            type="text"
                            id="city"
                            name="city"
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="Contoh: Bandung"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="dob" className="block text-sm font-medium mb-2">Tanggal lahir <span className="text-red-500">*</span></label>
                        <input
                            onChange={handleChange}
                            required
                            type="date"
                            id="dob"
                            name="dob"
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="YYYY-MM-DD"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="contact" className="block text-sm font-medium mb-2">Kontak yang bisa dihubungi (no WA atau akun instagram) <span className="text-red-500">*</span></label>
                        <input
                            onChange={handleChange}
                            required
                            type="text"
                            id="contact"
                            name="contact"
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="Contoh: @temanbicaraku_id"
                        />
                    </div>

                    {/* <div className="mb-4">
                        <label htmlFor="phone" className="block text-sm font-medium mb-2">Nomor Whatsapp <span className="text-red-500">*</span></label>
                        <input
                            onChange={handleChange}
                            required
                            type="number"
                            inputMode="numeric"
                            id="phone"
                            name="phone"
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                            placeholder="+62"
                        />
                    </div> */}

                    <div className="mb-4">
                        <label htmlFor="paymentMethod" className="block text-sm font-medium mb-2">
                            Metode Pembayaran <span className="text-red-500">*</span>
                        </label>
                        <div className='w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500'>
                            <input
                                onChange={handleChange}
                                type="radio"
                                id="paymentMethod"
                                name="paymentMethod"
                                checked
                                disabled
                                className="mr-2"
                            />
                            <span className="">Bank Transfer</span>
                            <div className="flex items-center gap-2">
                                <img src="uob.png" alt="UOB Logo" width={75} className="rounded-md" draggable={false} />
                                <div className="flex flex-col">
                                    <span className="font-bold text-primary">UOB</span>
                                    <span className="text-sm">3043732062</span>
                                    <span>Vania Devina Dharmadi</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="image" className="block text-sm font-medium mb-2">
                            Upload Bukti Pembayaran <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="file"
                            id="image"
                            name="image"
                            accept="image/*"
                            onChange={handleChange}
                            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                        />
                    </div>

                    <div className="mb-4">
                        <div className="flex justify-end">
                            <div className="flex flex-col">
                                <div className="flex gap-6 text-primary-lighter2">
                                    <span className="text-sm font-medium">{pricingInfo.desc}</span>
                                    <span className="text-sm font-medium ml-auto">Rp. {pricingInfo.price.toLocaleString('id-ID', { minimumFractionDigits: 2 })}</span>
                                </div>
                                <div className="flex gap-6">
                                    <span className="text-lg font-medium">Total</span>
                                    <span className="text-lg font-medium ml-auto underline">Rp. {pricingInfo.price.toLocaleString('id-ID', { minimumFractionDigits: 2 })}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="bg-primary-lighter2 text-white px-6 py-3 rounded-md hover:bg-primary-lighter1 transition mx-auto"
                        >
                            Daftar
                        </button>
                    </div>

                </form>

            </div>
        </Section>
    )
}

export default Form;