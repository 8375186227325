/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "../Home.css"
interface Testimonial {
  plan: string;
  message: string;
  rating: number;
  initials: string;
  date: string;
}

interface TestimonialSliderProps {
  testimonials: Testimonial[];
}

const TestimonialSlider: React.FC<TestimonialSliderProps> = ({ testimonials }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    mode: "free-snap",
    slides: {
      perView: 4,
      spacing: 15
    },
    breakpoints: {
      '(max-width: 1024px)': {
        slides: {
          perView: 2,
          spacing: 15
        }
      },
      '(max-width: 576px)': {
        slides: {
          perView: 1,
          spacing: 30
        }
      }
    }
  });

  const handlePrev = () => {
    instanceRef.current?.prev();
  };

  const handleNext = () => {
    instanceRef.current?.next();
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="flex gap-6 items-center">
      {screenWidth >= 576 && <span onClick={handlePrev} className="material-symbols-outlined hover:text-primary-lighter3 hover:scale-110 cursor-pointer">arrow_back_ios</span>
      }
      <div ref={sliderRef} className="keen-slider">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="keen-slider__slide cursor-grab ">
            <div className="border rounded-xl p-4 gap-2 text-left bg-white flex flex-col h-full box-border">

              <div>
                <p className="text-gray-700 font-bold">{testimonial.plan}</p>
                <p className="text-gray-700">"{testimonial.message}"</p>
              </div>
              <div className="flex items-center">
                <div className="flex items-center text-3xl text-yellow-400">
                  {Array.from({ length: testimonial.rating }, (_, i) => (
                    <span key={i}>★</span>
                  ))}
                </div>
              </div>

              <div className="flex items-center gap-2">
                <span className="material-symbols-outlined" style={{ fontSize: "36px" }}>
                  account_circle
                </span>
                <span className="text-gray-800 ">{testimonial.initials}</span>
              </div>


              <span className="text-gray-400  text-sm mt-auto text-right">
                {testimonial.date}
              </span>
            </div>
          </div>
        ))}
      </div>
      {screenWidth >= 576 && <span onClick={handleNext} className="material-symbols-outlined hover:text-primary-lighter3 hover:scale-110 cursor-pointer">arrow_forward_ios</span>}

    </div>
  );

};

export default TestimonialSlider;
