import React from "react";
import Section from "./components/Section";
import { FaFacebook, FaTwitter, FaInstagram, FaWhatsapp } from 'react-icons/fa';
const ComingSoon = () => {
    return (
        <Section className='bg-background-light text-primary-accent h-screen flex items-center text-center justify-center'>
            <div className="space-y-4">
                <p className="text-lg">#AYOKITACERITA</p>
                <div className='flex items-center justify-center'>
                    <img src="/logo.png" alt="" width="250vw" draggable="false" />
                    <h2 className="text-7xl lg:text-8xl font-bold">Work in progress.</h2>
                </div>
                <div className="flex gap-4 mb-4 justify-center">
                    <a href="https://www.facebook.com/your-facebook-page" target="_blank" rel="noopener noreferrer">
                        <FaFacebook size={32} className="text-primary hover:text-primary-lighter2 transition" />
                    </a>
                    <a href="https://twitter.com/your-twitter-handle" target="_blank" rel="noopener noreferrer">
                        <FaTwitter size={32} className="text-primary hover:text-primary-lighter2 transition" />
                    </a>
                    <a href="https://www.instagram.com/temanbicaraku_id/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram size={32} className="text-primary hover:text-primary-lighter2 transition" />
                    </a>
                    <a href="https://www.instagram.com/temanbicaraku_id/" target="_blank" rel="noopener noreferrer">
                        <FaWhatsapp size={32} className="text-primary hover:text-primary-lighter2 transition" />
                    </a>
                </div>
            </div>
        </Section>
    )
}

export default ComingSoon;