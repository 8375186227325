/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import Home from './pages/home/Home';
import reportWebVitals from './reportWebVitals';
import ComingSoon from './pages/home/ComingSoon';
import Form from './pages/confirmation/Confirmation';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path='/wip' Component={ComingSoon} />
        <Route path='/' Component={Home} />
        <Route path='/confirmation' Component={Form} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
