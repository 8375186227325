/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useRef, useState, useEffect } from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import TestimonialSection from './components/Testimonials';
import FAQSection from './components/FAQs';
import Section from './components/Section';
import ConsultationOption from './components/ConsultationOption';
import ConsultationSlider from './components/Consultation';
import ReactPlayer from 'react-player';
const HomePage: React.FC = () => {

  const chatCounselingPlan = [
    {
      id: 'chat-konseling-7',
      title: 'Chat Konseling',
      duration: '7 Hari (7 sesi)',
      price: 'Rp. 44.499,00 / Sesi',
      description: 'Untuk kamu yang mau intensif:',
      features: [
        'Pendampingan berkala selama 7 hari. 1 sesi durasinya 1 jam',
        'Dilakukan via Whatsapp',
        'Cocok untuk maintenance dan yang mau bertumbuh',
        'Konselor akan menjawab kamu secara interaktif selama durasi sesi',
        'Serasa punya teman dekat yang bisa mendengarkan masalah kamu dan memberikan tips serta masukan',
      ],
    },
    {
      id: 'chat-konseling-3',
      title: 'Chat Konseling',
      duration: '3 Hari (3 sesi)',
      price: 'Rp. 47.499,00 / Sesi',
      description: 'Untuk kamu yang mencari solusi lebih lanjut:',
      features: [
        'Pendampingan berkala selama 3 hari. 1 sesi durasinya 1 jam',
        'Dilakukan via Whatsapp',
        'Cocok untuk yang ingin melanjutkan konkseling 1 hari',
        'Konselor akan menjawab kamu secara interaktif selama durasi sesi',
        'Kamu bisa mendapatkan tips praktis dalam waktu singkat',
      ],
    },
    {
      id: 'chat-konseling-1',
      title: 'Chat Konseling',
      duration: '1 Hari (1 sesi)',
      price: 'Rp. 49.999,00 / Sesi',
      description: 'Untuk pemula dan mau coba konsultasi:',
      features: [
        'Pendampingan berkala selama 1 hari. 1 sesi durasinya 1 jam',
        'Dilakukan via Whatsapp',
        'Cocok untuk yang baru pertama kali on-going counseling',
        'Konselor akan menjawab kamu secara interaktif selama durasi sesi',
        'Kamu bisa mendapatkan tips praktis dalam waktu singkat',
      ],
    },
  ];

  const telponCounselingPlan = [{
    id: 'telpon-konseling-3',
    title: 'Telpon Konseling',
    duration: '3 Hari (3 Sesi)',
    price: 'Rp. 69.999,00 / Sesi',
    description: 'Program konseling melalui telepon untuk sesi yang lebih fleksibel:',
    features: [
      'Durasi selama 1 jam per sesinya',
      'Dilakukan via Whatsapp',
      'Dapat berdiskusi langsung tentang masalah yang dihadapi dan dapat masukan secara instant',
      'Dapat berbincang dengan konselor kami secara realtime',
    ],
  }, {
    id: 'telpon-konseling-1',
    title: 'Telpon Konseling',
    duration: '1 Hari (1 Sesi) ',
    price: 'Rp. 74.499,00 / Sesi',
    description: 'Program konseling melalui telepon untuk sesi yang lebih fleksibel:',
    features: [
      'Durasi selama 1 jam per sesinya',
      'Dilakukan via Whatsapp',
      'Dapat berdiskusi langsung tentang masalah yang dihadapi dan dapat masukan secara instant',
      'Dapat berbincang dengan konselor kami secara realtime',
    ],
  }];

  const videoCounselingPlan = [{
    id: 'video-konseling-3',
    title: 'Video Konseling',
    duration: '3 Hari (3 Sesi)',
    price: 'Rp. 89.999,00 / Sesi',
    description: 'Program konseling melalui video call untuk sesi yang lebih interaktif:',
    features: [
      'Durasi selama 1 jam per sesinya',
      'Dilakukan di Zoom / Google Meet',
      'Dapat berdiskusi langsung tentang masalah yang dihadapi dan dapat masukan secara instant',
      'Dapat berbincang dengan konselor kami secara realtime',
    ],
  }, {
    id: 'video-konseling-1',
    title: 'Video Konseling',
    duration: '1 Hari (1 Sesi)',
    price: 'Rp. 99.999,00 / Sesi',
    description: 'Program konseling melalui video call untuk sesi yang lebih interaktif:',
    features: [
      'Durasi selama 1 jam per sesinya',
      'Dilakukan di Zoom / Google Meet',
      'Dapat berdiskusi langsung tentang masalah yang dihadapi dan dapat masukan secara instant',
      'Dapat berbincang dengan konselor kami secara realtime',
    ],
  }];
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(loadingTimeout);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const targetRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div>
      {/* <nav className="bg-blue-500 p-4 text-white">
        <div className="container mx-auto">
          <h1 className="text-2xl font-semibold">Home</h1>
        </div>
      </nav> */}
      {/* <Section className='bg-background-light text-primary h-screen flex items-center text-center justify-center'>
        <div className="space-y-4">
          <p className="text-lg">#AYOKITACERITA</p>
          <div className='flex items-center justify-center flex-col lg:flex-row'>
            <img src="/logo.png" alt="" width={"250vw"} draggable="false" />
            <h2 className="text-7xl lg:text-8xl font-bold">Teman Bicaraku</h2>
          </div>
          <button onClick={handleScroll} className="rounded-xl font-semibold border border-secondary-darker1 text-secondary-darker2 hover:bg-secondary-lighter2 hover:text-primary-accent px-12 py-4 transition">LEARN MORE</button>
        </div>
      </Section> */}

      <div style={{ height: '100vh', position: 'relative' }}>
        <video src="video.mp4" autoPlay loop muted style={{ position: 'absolute', top: 0, left: 0, zIndex: -1, width: '100%', height: '100%', objectFit: 'cover', opacity: 0.4 }}></video>
        <Section className='text-primary h-screen flex items-center text-center justify-center'>
          <p className="text-lg font-semibold">#AYOKITACERITA</p>
          <div className='flex items-center justify-center flex-col lg:flex-row' style={{ zIndex: 2 }}>
            {/* On mobile (md:flex-col), stack items vertically */}
            {/* <img src="/logo.png" alt="" width={"250vw"} draggable="false" /> */}
            <h2 className="text-7xl lg:text-8xl font-bold my-20">Teman Bicaraku</h2>
          </div>
          <button
            onClick={handleScroll}
            className="rounded-xl font-medium border-2 border-primary text-primary hover:bg-background-light hover:scale-105 hover:bg-opacity-0.5 hover:text-primary-accent px-12 py-4 transition"
          >
            LEARN MORE
          </button>
        </Section>
      </div>



      <Section className='bg-background-light text-primary-accent flex items-center justify-center'>
        <div className='flex flex-col justify-center items-center md:flex-row'>
          <div className='flex flex-col pb-4'><img src="/logo.png" alt="" width="250vw" draggable="false" /></div>
          <div className='flex flex-col gap-8 text-justify lg:flex-row'>
            <div className='flex flex-col max-w-sm '>
              <p className="text-lg">
                <strong>Teman bicaraku adalah platform</strong> dimana kami menyediakan tempat dan wadah dimana setiap
                orang bisa <strong>menceritakan</strong> masalahnya tanpa perlu takut untuk di judge. Kami percaya bahwa tiap
                orang punya cerita dan butuh <strong>teman</strong> untuk bisa <strong>mendengar</strong> masalahnya.
              </p>
            </div>
            <div className='flex flex-col max-w-sm'>
              <p className="text-lg">
                <strong>Teman Bicaraku siap menjadi telinga</strong> untuk mendengar tiap orang dari berbagai latar belakang
                dan siap membantu memberikan <strong>perspektif lain</strong> supaya tiap orang bisa menyelesaikan <strong>masalahnya</strong> dengan lebih bijak.
              </p>
            </div>
          </div>
        </div>
      </Section>


      <Section className="bg-background-light text-primary-accent flex items-center">
        <div className='flex flex-col lg:flex-row gap-12 justify-center align-center items-center'>
          <div className='max-w-3xl order-1'>
            {/* <h2 className="text-4xl font-bold mb-4 text-center">Introduction</h2> */}
            <p className="mb-4 lg:text-left text-lg">
              Halo, perkenalkan saya <strong>Vania Devina Dharmadi</strong>. Kamu bisa panggil <strong>Vania</strong>.
            </p>
            <p className="lg:text-left text-lg">
              Saya adalah seorang konselor psikologi dengan latar belakang pendidikan
            </p>
            <p className='mb-4 lg:text-left text-lg'>
              <strong>Bachelor of Psychology</strong> dari <strong>James Cook University</strong> di <strong>Singapore</strong>. Setelah lulus pada tahun 2017, saya fokus dalam profesi konselor psikologi, memberikan bimbingan kepada klien dari berbagai negara.
            </p>
            <p className="lg:text-left text-lg">
              Berikut adalah bidang fokus dan keahlian saya dalam sesi konsultasi:
            </p>
            <ul className="list-disc pl-6 mb-8 lg:text-left text-lg">
              <li>Kepercayaan diri dan overthinking</li>
              <li>Emosi</li>
              <li>Trauma masa lalu</li>
              <li>Stress, burnout, dan kecemasan</li>
              <li>Kesepian</li>
              <li>Masalah hubungan: pertemanan, pasangan, dan keluarga</li>
            </ul>
          </div>
          <div className='lg:order-1'>
            <img className='bg-secondary-accent2 border rounded-full w-[250px] h-[250px] mx-auto' src="profile.jpg" alt="" />
            {/* <div className='bg-secondary-accent2 border rounded-full w-[250px] h-[250px] mx-auto'>
              <div className='w-[250px] h-[250px] flex items-center justify-center'>
                <span className='text-white'>Foto Kamu</span>
              </div>
            </div> */}
          </div>
        </div>
      </Section>

      <div ref={targetRef}>
        <Section className='bg-background-light text-primary py-4'>
          <h2 className="text-6xl font-bold mb-8 text-center">Pilihan Konsultasi</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div>
              <ConsultationSlider plans={chatCounselingPlan} />
            </div>
            <div className=''>
              <ConsultationSlider plans={telponCounselingPlan} />
              {/* <ConsultationOption {...telponCounselingPlan}></ConsultationOption> */}
            </div>
            <div>
              <ConsultationSlider plans={videoCounselingPlan} />
              {/* <ConsultationOption {...videoCounselingPlan}></ConsultationOption> */}
            </div>
          </div>
        </Section>
      </div>

      <Section className='bg-background-light text-primary text-center justify-center'>
        <p className="text-lg">#AYOKITACERITA</p>
        <h2 className="text-3xl font-semibold mb-4">APA KATA MEREKA</h2>
        {TestimonialSection()}
      </Section >

      <div className='bg-background-light'>
        <h2 className="text-primary text-4xl font-bold mb-4 text-center">Frequently Asked Questions</h2>
        <Section className='bg-secondary text-white  flex items-center justify-center'>
          <div className='max-w-4xl mx-auto'>
            <div className='text-primary'>
              {FAQSection()}
            </div>
          </div>
        </Section>
      </div>



      <footer className="bg-secondary-darker2 text-secondary-lighter2 p-4 text-white">
        <div className="container mx-auto text-center flex flex-col items-center justify-center text-xs">
          <div className="flex gap-4 mb-4">
            {/* <a href="https://www.facebook.com/your-facebook-page" target="_blank" rel="noopener noreferrer">
              <FaFacebook size={24} className="text-white hover:text-primary-lighter1 transition" />
            </a>
            <a href="https://twitter.com/your-twitter-handle" target="_blank" rel="noopener noreferrer">
              <FaTwitter size={24} className="text-white hover:text-primary-lighter1 transition" />
            </a> */}
            <a href="https://www.instagram.com/temanbicaraku_id/" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={24} className="text-white hover:text-primary-lighter1 transition" />
            </a>
            <a href="https://wa.me/6285158881680" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp size={24} className="text-white hover:text-primary-lighter1 transition" />
            </a>
          </div>
          <p>&copy; 2024 Vania Devina Dharmadi. All rights reserved.</p>
        </div>
      </footer>
    </div >
  );
};

export default HomePage;
